<template>
  <div class="cart-list-container" :class="[theme]">
    <div class="cart__title">
      <p>{{ title }}</p>
      <p class="delete-all" v-if="theme === 'useless'">全部删除</p>
    </div>
    <div class="list-header" v-if="isShowHeader">
      <p>商品信息</p>
      <p>单价（元）</p>
      <p>数量</p>
      <p>小计（元）</p>
      <p>操作</p>
    </div>
    <div class="list__main-body">
      <el-form :model="form" v-if="lists.length">
        <el-checkbox-group
          v-model="form.listSelected"
          @change="onCheckedChange"
        >
          <div class="list__item" v-for="(list, index) in lists" :key="index">
            <div class="item__child">
              <el-checkbox :label="list.id">
                <div class="product__cover">
                  <img :src="list.goods.cover.normal | parseImage" alt="" />
                </div>
                <div class="product__name">
                  <p>{{ list.goods.category.name.cn }}-{{ list.goods.name }}</p>
                  <p>{{ list.extends }}</p>
                  <template v-if="$bowser.mobile">
                    <p class="product__total-price">
                      ￥ {{ list.counts * list.goods.price }}
                    </p>
                  </template>
                </div>
              </el-checkbox>
            </div>
            <div class="item__child --price">￥ {{ list.goods.price }}</div>
            <div class="item__child">
              <el-input-number
                v-model="list.counts"
                :min="1"
                :max="99"
                v-if="theme === 'normal'"
                @change="onCountChange(list.id, list.counts)"
              ></el-input-number>
              <p v-else-if="theme === 'useless'" class="product-status">售罄</p>
            </div>
            <div class="item__child --price">
              ￥ {{ list.counts * list.goods.price }}
            </div>
            <div
              class="item__child --action-del"
              @click.stop="onDel"
              :data-id="list.id"
            >
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-checkbox-group>
      </el-form>
      <div v-else-if="title === '购物车'">
        <empty
          title="购物车里没有商品"
          hint="您没有添加购物车商品br您可以在此查看已添加购物车的商品"
          :bt="{
            type: 'inner',
            link: '/products',
            title: '浏览商品'
          }"
        ></empty>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import empty from "@/components/empty.vue";
export default {
  name: "c_cart-list",
  model: {
    prop: "selectedCarts"
  },
  components: {
    empty
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    isShowHeader: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: "normal"
    },
    lists: {
      type: Array,
      default: null
    },
    selectedCarts: Array
  },
  data() {
    return {
      form: {
        listSelected: [],
        num: 1
      },
      innerSelected: this.selectedCarts
    };
  },
  watch: {
    selectedCarts(val) {
      if (val.length > 0) {
        this.form.listSelected = val.map(item => item.id);
      } else if (val.length === 0) {
        this.form.listSelected = [];
      }
      this.innerSelected = val;
    }
  },
  methods: {
    ...mapMutations("shop", ["DEL_CART", "UPDATE_CART"]),
    onDel(e) {
      const that = this;
      const id = e.currentTarget.dataset.id;
      that
        .$confirm("是否确定删除此商品？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.$request.del(`/api/user/cart/?id=${id}`).then(() => {
            that.DEL_CART(id);
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    onCountChange(id, val) {
      const that = this;
      let isChange = false;
      that.innerSelected = that.innerSelected.map(item => {
        const temp = item;
        if (Number(temp.id) === Number(id)) {
          temp.counts = val;
          temp.total_price = temp.goods.price * val;
          isChange = true;
          that.UPDATE_CART(temp);
        }
        return temp;
      });
      if (isChange) {
        that.$emit("input", that.innerSelected);
      }
    },
    onCheckedChange(val) {
      const that = this;
      const checkedCarts = val.map(item => {
        const cart = that.lists.find(c => Number(c.id) === Number(item));
        cart.total_price = Number(cart.counts) * Number(cart.goods.price);
        return cart;
      });
      // that.selectedCarts = checkedCarts;
      that.innerSelected = checkedCarts;
      this.$emit("input", checkedCarts);
    }
  }
};
</script>

<style></style>
