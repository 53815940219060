<template>
  <div class="empty-container" ref="wrapper">
    <div class="title" v-if="title">{{ title }}</div>
    <div class="hint">
      <p v-for="(text, index) in hint.split('br')" :key="index">
        {{ text }}
      </p>
    </div>
    <div class="bt" v-if="bt">
      <template v-if="bt.type === 'extend'">
        <a class="bt__link" :href="bt.link">{{ bt.title }}</a>
      </template>
      <template v-else-if="bt.type === 'inner'">
        <router-link
          class="bt__link"
          :to="{
            path: bt.link
          }"
          >{{ bt.title }}</router-link
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "c_page-empty",
  props: {
    title: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      required: true
    },
    bt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      height: window.innerHeight
    };
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, false);
  }
};
</script>

<style></style>
