<template>
  <div class="page-common-body cart-list-page-container">
    <div class="cart-list-main-container">
      <cart-list
        class="useful-cart-list"
        :lists="list"
        title="购物车"
        @onChecked="onChecked"
        v-model="checkedCarts"
      ></cart-list>
      <cart-list
        v-if="uselessCarts.length > 0"
        class="useless-cart-list"
        title="失效商品"
        :lists="uselessCarts"
        :isShowHeader="false"
        theme="useless"
      ></cart-list>
    </div>
    <!-- 结算条 -->
    <div class="cart-checkout-container" v-if="list.length > 0">
      <div class="checkout-main-body">
        <el-checkbox
          class="choose__all"
          :indeterminate="isIndeterminate"
          v-model="cartChooseVal"
          @change="doChooseCart"
          >全选</el-checkbox
        >
        <div class="checkout__info">
          <p v-if="!$bowser.mobile">
            共计 <span>{{ checkedCarts.length }}</span> 件商品
          </p>
          <p>
            合计：<strong>￥{{ checkedTotalPrice }}</strong>
          </p>
        </div>
        <el-button class="cart__submit" :loading="requesting" @click="onSubmit"
          >结算<template v-if="$bowser.mobile"
            >({{ checkedCarts.length }})</template
          >
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cartList from "./_list";
export default {
  name: "page-cart-list",
  components: {
    cartList
  },
  data() {
    return {
      list: [],
      uselessCarts: [],
      checkedCarts: [],
      cartChooseVal: false,
      requesting: false
    };
  },
  computed: {
    ...mapState("shop", ["carts"]),
    checkedTotalPrice() {
      const that = this;
      let total = 0;
      if (that.checkedCarts.length > 0) {
        that.checkedCarts.forEach(item => {
          total += Number(item.total_price);
        });
      }
      return total;
    },
    isIndeterminate() {
      return (
        this.checkedCarts.length > 0 &&
        this.checkedCarts.length < this.carts.length
      );
    }
  },
  watch: {
    carts: {
      handler(val) {
        this.list = val;
      },
      immediate: true
    },
    checkedCarts(val) {
      this.cartChooseVal = val.length > 0 && val.length === this.carts.length;
    }
  },
  methods: {
    onChecked(val) {
      console.log(val);
    },
    doChooseCart(val) {
      if (val) {
        this.checkedCarts = this.carts;
      } else {
        this.checkedCarts = [];
      }
    },
    onSubmit() {
      const that = this;
      if (that.checkedCarts.length === 0) {
        that.$message({
          type: "error",
          message: "请选择至少一样物品进行结算"
        });
        return;
      } else {
        if (!that.requesting) {
          that.requesting = true;
          const cartIds = [];
          const mutilRequests = that.checkedCarts.map(item => {
            cartIds.push(item.id);
            return that.$request.post(`/api/user/cart/?id=${item.id}`, {
              gid: item.gid,
              counts: item.counts,
              total_price: item.total_price,
              extends: item.extends
            });
          });
          Promise.all(mutilRequests)
            .then(() => {
              that.$router.push({
                path: `/order/create/${cartIds.join(",")}`
              });
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              that.requesting = false;
            });
        }
      }
    }
  }
};
</script>

<style></style>
